import { Route, useNavigate } from '@tanstack/router'
import { useEffect } from 'react'

import inIframe from '../utils/inIframe'
import { rootRoute } from './root'

export const gameBackRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/game-back',
  component: () => {
    const navigate = useNavigate()

    useEffect(() => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type : "back" }))
      } else if (inIframe()) {
        window.parent.postMessage(JSON.stringify({ type : "back" }), '*')
      } else {
        navigate({ to: '/' })
      }
    }, [])

    return null
  },
})
